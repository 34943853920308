<template>
  <div class="calendar-filter">
    <div class="calendar-period-header">
      <div class="calendar-period-action prev"
           @click="flipMonth(-1)"
      />
      <div class="calendar-filter-month">{{ formatMonth }}</div>
      <div class="calendar-period-action next"
           @click="flipMonth(1)"
      />
    </div>
    <div class="calendar-filter-grid">
      <div class="calendar-filter-weekdays">
        <div
          v-for="(name, i) of dayNames"
          :key="'h' + i"
          class="calendar-filter-weekday-name"
        >
          {{ name }}
        </div>
      </div>
      <div class="calendar-filter-days">
        <div
          v-for="d in month.daysInMonth()"
          :style="d === 1 ? { 'grid-column-start': month.isoWeekday() } : undefined"
          :key="'d' + (d - 1)"
          :class="getDayClass(d)"
        >
          <div class="day"
             @click="selectDay(d)"
          >
            {{ d }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
moment.locale('ru')

export default {
  name: 'Calendar',
  props: {
    month: { type: Object, required: true },
    period: { type: Array, required: true },
  },
  computed: {
    endOfMonth () {
      return this.month.clone().endOf('month')
    },
    dayNames () {
      const weekDays = moment.weekdaysMin()
      const firstWeekday = moment.localeData()._week.dow
      return weekDays.map((name, index) => weekDays[(index + firstWeekday) % 7])
    },
    formatMonth() {
      return moment(this.month).format('MMMM YYYY')
    }
  },
  methods: {
    selectDay (d) {
      return this.$emit('select', this.month.clone().add({ days: d - 1 }))
    },
    flipMonth (months) {
      return this.$emit('flip', this.month.clone().add({ months }))
    },
    getDayClass (d) {
      const wrapped = this.month.clone().add({ days: d - 1 })
      const weekday = wrapped.isoWeekday()
      const sameDay = reference => wrapped.isSame(reference, 'day')
      return ['calendar-filter-day', {
        'calendar-filter-day_today': this.period.some(sameDay),
        'calendar-filter-day_selected': this.period.some(sameDay),
        'calendar-filter-day_between': wrapped.isBetween(...this.period, 'day'),
        'calendar-filter-day_first': weekday === 1 || sameDay(this.month) || sameDay(this.period[0]),
        'calendar-filter-day_last': weekday === 7 || sameDay(this.endOfMonth) || sameDay(this.period[1]),
        'calendar-filter-day_weekend': weekday >= 6,
      }]
    },
  },
}
</script>

<style lang="scss">

.calendar-period-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #00004f;
  padding: 0 10px;

  @media screen and (max-width: 1279px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 24px;
  }
}

.calendar-period-action {
  border: 2px solid #8080A7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color .2s ease, background-color .2s ease;

  @media screen and (max-width: 1279px) {
    width: 18px;
    height: 18px;
  }

  @media screen and (min-width: 1280px) {
    width: 24px;
    height: 24px;
  }

  &::before {
    font-family: fontello;

    @media screen and (max-width: 1279px) {
      font-size: 8px;
    }

    @media screen and (min-width: 1280px) {
      font-size: 12px;
    }
  }

  &.prev::before {
    content: '\e83f';
    margin-right: 1px;
  }

  &.next::before {
    content: '\e841';
    margin-left: 1px;
  }

  &:hover {
    background: #5378FB;
    border-color: #5378FB;
    color: #fff;
  }
}

.calendar-filter {
  padding: 10px 9px 5px 10px;
  user-select: none;

  &-month {
    text-transform: capitalize;
  }

  &-weekdays, &-days {
    display: grid;
    grid-template-columns: repeat(7, auto);
  }

  &-weekdays {
    grid-gap: 0 15px;
    text-transform: capitalize;
    border-bottom: 1px solid fade-out(#00004F, .9);
  }

  &-days {
    grid-gap: 8px 0;
    padding-top: 8px;
  }

  &-grid {
    margin-top: 20px;
  }

  &-weekday-name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: fade-out(#00004F, .5);

    @media screen and (max-width: 1279px) {
      height: 30px;
      font-size: 14px;
    }

    @media screen and (min-width: 1280px) {
      height: 40px;
      font-size: 16px;
    }
  }

  &-day {
    $el: &;

    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-sizing: border-box;
    //border: 2px solid transparent;

    cursor: pointer;
    font-weight: 500;

    @media screen and (max-width: 1279px) {
      font-size: 14px;
      line-height: 19px;
      width: 40px;
      height: 40px;
    }

    @media screen and (min-width: 1280px) {
      font-size: 16px;
      line-height: 24px;
      width: 45px;
      height: 45px;
    }

    .day {
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 1279px) {
        width: 30px;
        height: 30px;
      }

      @media screen and (min-width: 1280px) {
        width: 40px;
        height: 40px;
      }
    }

    &:hover,
    &_selected, &_today {
      .day {
        //border-color: #5378FB;
        //border-radius: 50%;
        //background-color: #5378FB;
        //color: #fff;
      }
      border-color: #5378FB;
      border-radius: 50%;
      background-color: #5378FB;
      color: #fff;
    }

    &_selected {
      &.calendar-filter-day_first::before, &.calendar-filter-day_last::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #DFDFFF;
        z-index: -1;
      }

      &.calendar-filter-day_first::before {
        border-bottom-left-radius: 50%;
        border-top-left-radius: 50%;
      }

      &.calendar-filter-day_last {
        &::before {
          border-bottom-right-radius: 50%;
          border-top-right-radius: 50%;
        }
      }
    }

    &_between {
      background-color: #DFDFFF;
      color: #00004F;

      //.day {
      //  background-color: #DFDFFF;
      //  color: #00004F;
      //}

      &.calendar-filter-day_weekend {
        color: #0d9a9a;

        &:hover {
          color:  #fff;
        }
      }
    }

    & .expander {
      margin-bottom: 4px;

      &__edge {
        cursor: pointer;

        &:first-child {
          transform: rotate(90deg);
        }

        &:last-child {
          transform: rotate(-90deg);
        }
      }

      &__content {
        justify-content: center;
        text-transform: capitalize;
      }
    }

    //&_first:not(#{&}_last) {
    //  border-top-left-radius: 5px;
    //  border-bottom-left-radius: 5px;
    //}
    //
    //&_last:not(#{&}_first) {
    //  border-top-right-radius: 5px;
    //  border-bottom-right-radius: 5px;
    //}

  }
}
</style>