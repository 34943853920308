
import { defineComponent } from "@vue/runtime-core";
import AppPage from "@/components/AppPage.vue";
import DataTable from "@/components/DataTable.vue";
import Daterange from "@/components/Daterange.vue";
import SubsetInput from "@/components/SubsetInput.vue";
import { Option, Pagination, Sorting } from "@/use/types";
import moment, { Moment } from "moment-timezone";

import axios from "axios";

interface IDataModel {
  loading: boolean;
  filter: IQuery;
  rows: any[];
  totalRows: number;
  totals: any[];
  columns: Column[];
  definition: any;
  options: {
    advertisers: Option[];
    offers: Option[];
    transits: Option[];
    teasers: Option[];
    landings: Option[];
    affiliates: Option[];
    campaigns: Option[];
    teaser_categories: Option[];
    news: Option[];
    news_categories: Option[];
    page_types: Option[];
    devices: Option[];
    date_format: Option[];
  };
}

interface IQuery {
  period: Moment[];
  timezone: string;
  search: string;
  pagination: null | Pagination;
  sorting: Sorting;
  advertiser_ids: number[];
  offer_ids: number[];
  transit_links: string[];
  teaser_ids: number[];
  landing_links: string[];
  affiliate_ids: number[];
  campaign_ids: number[];
  teaser_category_ids: number[];
  news_ids: number[];
  news_category_ids: number[];
  device_ids: number[];
  date_format_types: string[];
  page_type_ids: number[];
}

interface Column {
  id: number | string;
  name: string;
  selected: boolean;
  freezed?: boolean;
}

export function getDefaultQuery(): IQuery {
  return {
    period: [moment().startOf("month"), moment().endOf("day")],
    timezone: "Europe/Kiev",
    search: "",
    sorting: { column: "created_at", direction: "desc" },
    pagination: { limit: 10, offset: 0 },
    advertiser_ids: [],
    offer_ids: [],
    transit_links: [],
    teaser_ids: [],
    landing_links: [],
    affiliate_ids: [],
    campaign_ids: [],
    teaser_category_ids: [],
    news_ids: [],
    news_category_ids: [],
    device_ids: [],
    date_format_types: ["day"],
    page_type_ids: [],
  };
}

const dataColumns = [
  {
    id: "created_at",
    name: "Дата",
    selected: true,
    sortable: true,
    freezed: true,
    htmlClass: "date-column",
  },
  {
    id: "bots",
    name: "Боты",
    selected: true,
    sortable: true,
  },
  {
    id: "visits",
    name: "Визиты",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "users",
    name: "Уники",
    selected: true,
    sortable: false,
  },
  {
    id: "clicks",
    name: "Клики",
    selected: true,
    sortable: true,
  },
  {
    id: "views",
    name: "Просмотры страницы",
    selected: true,
    sortable: true,
  },
  {
    id: "breaking",
    name: "Пробив страницы, %",
    selected: true,
    sortable: true,
  },
  {
    id: "ctr",
    name: "CTR",
    selected: true,
    sortable: true,
    htmlClass: "highlighted-column",
  },
  {
    id: "cr",
    name: "CR",
    selected: true,
    sortable: true,
    htmlClass: "highlighted-column",
  },
  {
    id: "epc",
    name: "EPC",
    selected: true,
    sortable: true,
    htmlClass: "highlighted-column",
  },
  {
    id: "valid_leads",
    name: "Лиды",
    selected: true,
    sortable: true,
  },
  {
    id: "waiting_rub",
    name: "Доход в ожид. (руб.)",
    selected: true,
    sortable: true,
  },
  {
    id: "waiting_usd",
    name: "Доход в ожид. (usd.)",
    selected: true,
    sortable: true,
  },
  {
    id: "approved_leads",
    name: "Апрув",
    selected: true,
    sortable: true,
  },
  {
    id: "rejected_leads",
    name: "Отмена",
    selected: true,
    sortable: true,
  },
  // {
  //   id: "margin",
  //   name: "Маржа",
  //   selected: true,
  //   sortable: true,
  // },
];

export default defineComponent({
  components: {
    AppPage,
    SubsetInput,
    DataTable,
    Daterange,
  },
  data(): IDataModel {
    return {
      loading: false,
      filter: getDefaultQuery(),
      rows: [],
      totalRows: 0,
      totals: [],
      columns: dataColumns,
      definition: {
        shortcuts: {
          Сегодня: [moment().startOf("day"), moment().endOf("day")],
          Вчера: [
            moment().subtract(1, "days").startOf("day"),
            moment().subtract(1, "days").endOf("day"),
          ],
          "Последние 3 дня": [
            moment().subtract(3, "days").startOf("day"),
            moment().endOf("day"),
          ],
          "Последние 7 дней": [
            moment().subtract(7, "days").startOf("day"),
            moment().endOf("day"),
          ],
          "Текущий месяц": [moment().startOf("month"), moment().endOf("month")],
          "Прошедший месяц": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
          "Текущий год": [moment().startOf("year"), moment().endOf("year")],
        },
        timezone: "Europe/Kiev",
      },
      options: {
        advertisers: [],
        offers: [],
        transits: [],
        teasers: [],
        landings: [],
        affiliates: [],
        campaigns: [],
        teaser_categories: [],
        news: [],
        news_categories: [],
        page_types: [],
        devices: [],
        date_format: [
          { id: "hour", name: "Часовой" },
          { id: "day", name: "По дате" },
        ],
      },
    };
  },
  async created() {
    this.load();
  },
  mounted() {
    this.$watch("filter.sorting", this.load);
    this.$watch("filter.pagination", this.load);
  },
  methods: {
    setPeriod(value: any) {
      this.filter.period = value;
    },
    updateLookupValues(e: any, field?: string) {
      const key:
        | "advertiser_ids"
        | "offer_ids"
        | "transit_links"
        | "teaser_ids"
        | "landing_links"
        | "affiliate_ids"
        | "campaign_ids"
        | "teaser_category_ids"
        | "news_ids"
        | "news_category_ids"
        | "date_format_types"
        | "device_ids" = field as any;
      this.filter[key] = e;
    },
    async filterOptions(collection: string, query = "", field?: string) {
      const { data } = await axios.get(
        "/api/report-lookups/" + collection + "/lookup",
        {
          params: { q: JSON.stringify({ query }) },
        }
      );
      const key:
        | "advertisers"
        | "offers"
        | "transits"
        | "teasers"
        | "landings"
        | "affiliates"
        | "campaigns"
        | "teaser_categories"
        | "news"
        | "news_categories"
        | "page_types"
        | "devices" = (field || collection) as any;
      this.options[key] = data;
    },
    setColumns(newColumns: Column[]) {
      this.columns = newColumns.map((c) => ({
        ...c,
        selected: c.selected,
      }));
    },
    async load() {
      this.loading = true;
      try {
        const { data } = await axios.get("/api/statistics", {
          params: {
            q: JSON.stringify({
              filter: this.filter,
              sorting: this.filter.sorting,
              pagination: this.filter.pagination,
            }),
          },
        });
        this.rows = data.rows;
        this.totalRows = data.totalRows;
        this.totals = data.totals;

        this.filterOptions("advertisers");
        this.filterOptions("offers");
        this.filterOptions("transits");
        this.filterOptions("teasers");
        this.filterOptions("landings");
        this.filterOptions("affiliates");
        this.filterOptions("campaigns");
        this.filterOptions("teaser_categories");
        this.filterOptions("news");
        this.filterOptions("news_categories");
        this.filterOptions("page_types");
        this.filterOptions("devices");
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
      this.loading = false;
    },
    clear() {
      this.filter = getDefaultQuery();
      this.load();
    },
  },
});
