<template>
  <div
    class="calendar"
    :class="{ opened: opened }"
    v-click-outside="() => (opened = false)"
  >
    <div class="calendar-trigger" @click="opened = !opened" @close="close">
      <span>Дата</span>
      <div class="calendar-trigger__inner">
        {{ intervalLabel }}
        <i class="icon-calendar"></i>
      </div>
    </div>
    <div class="calendar-dropdown" v-if="opened">
      <div class="calendar-wrapper">
        <ul class="calendar-list">
          <li
            class="calendar-list-item"
            v-for="item in shortcutItems"
            :key="item.id"
            @click="selectShortcut(item.id)"
            :class="{ active: activeItem === item.id }"
          >
            <label>
              {{ item.name }}
            </label>
          </li>
          <li
            class="calendar-list-item custom"
            :class="{ active: activeItem === null }"
            @click="activeItem = null"
          >
            <label> Кастомно </label>
          </li>
        </ul>
        <div
          v-if="months[0] !== null && months[1] !== null"
          class="calendar-wrapper__inner"
        >
          <Calendar
            v-for="(m, i) of months"
            :key="i"
            :month="m"
            :period="selectedPeriod"
            @flip="changeMonth(i, $event)"
            @select="changeEdge"
          />
        </div>
      </div>
      <div class="calendar-footer">
        <Button
          type="button"
          @click="selectShortcut(shortcutItems[0].id)"
          text="Отменить"
          className="_outlined"
        />
        <Button
          class="btn-primary"
          className="_filled"
          type="button"
          @click="accept"
          text="Выбрать"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Calendar from "./Calendar";
import Button from "./Button.vue";

moment.locale("ru");

export default {
  name: "CalendarContainer",
  components: { Calendar, Button },
  props: {
    value: { type: Array, required: true },
    definition: { type: Object },
    disabled: { type: Boolean, default: false },
  },
  emits: ["input"],
  data: () => ({
    opened: false,
    focused: false,
    selectedEdge: null,
    months: [null, null],
    activeItem: 8,
  }),
  computed: {
    shortcutItems() {
      const edge = (edge) => (what, addendum) =>
        moment().tz(this.timezone).add(addendum)[edge + "Of"](what);

      const start = edge("start");
      const end = edge("end");

      const range = (what, addendum) => [
        start(what, addendum),
        end(what, addendum),
      ];

      const items = this.definition.shortcuts || {
        Сегодня: range("day"),
        Вчера: range("day", { days: -1 }),
        "Последние 3 дня": [start("day", { days: -3 }), end("day")],
        "Последние 7 дней": [start("day", { days: -7 }), end("day")],
        "Текущий месяц": range("month"),
        "Прошедший месяц": range("month", { months: -1 }),
        "Текущий год": range("year"),
        "Прошедший год": range("year", { years: -1 }),
        "Весь период": [],
      };

      if (this.definition.shortcuts) {
        this.setActiveShortcut(4);
      }

      return Object.keys(items).map((k, i) => ({
        id: i,
        name: k,
        range: items[k],
      }));
    },
    timezone() {
      return this.definition.timezone || "Europe/Kiev";
    },
    wrappedValue() {
      return this.value.length
        ? [
            moment.tz(this.value[0], this.timezone).startOf("day"),
            moment.tz(this.value[1], this.timezone).endOf("day"),
          ]
        : [];
    },
    selectedPeriod() {
      return this.selectedEdge
        ? [this.selectedEdge, this.selectedEdge]
        : this.wrappedValue;
    },
    intervalLabel() {
      const [from, to] = this.value;

      if (this.value.length) {
        const isSame = (what) => moment(from).isSame(to, what);

        if (isSame("day")) {
          return moment(from).format("D MMMM YYYY");
        }

        if (isSame("month")) {
          return `${moment(from).format("D")}—${moment(to).format(
            "D MMM YYYY"
          )}`;
        }

        if (isSame("year")) {
          return `${moment(from).format("D MMM")} — ${moment(to).format(
            "D MMM YYYY"
          )}`;
        }
        return `${moment(from).format("DD/MM/YYYY")} — ${moment(to).format(
          "DD/MM/YYYY"
        )}`;
      }

      return "Весь период";
    },
  },
  watch: {
    opened(newValue) {
      if (!newValue) {
        this.selectedEdge = null;
      }
    },
    value() {
      this.initializeMonths();
    },
    definition() {
      this.initializeMonths();
    },
    activeItem(newValue) {
      if (this.shortcutItems[newValue]) {
        this.$emit("input", this.shortcutItems[newValue].range);
      }
    },
  },
  created() {
    this.initializeMonths();
  },
  methods: {
    selectShortcut(index) {
      this.setActiveShortcut(index);
    },
    setActiveShortcut(index) {
      this.activeItem = index;
    },
    close() {
      this.opened = false;
    },
    initializeMonths() {
      if (this.value.length && this.months) {
        const isSameMonth = moment(this.value[0]).isSame(
          moment(this.value[1]),
          "month"
        );
        this.months[0] = moment(this.value[0])
          .clone()
          .startOf("month")
          .add({ months: isSameMonth ? -1 : 0 });
        this.months[1] = moment(this.value[1]).clone().startOf("month");
      } else {
        this.months = [null, null];
      }
    },
    changeMonth(index, value) {
      if (value.isSame(this.months[1 - index], "month")) {
        this.months[1 - index] = this.months[1 - index]
          .clone()
          .add({ months: index === 0 ? 1 : -1 });
      }
      this.months[index] = value;
    },
    changeEdge(value) {
      this.activeItem = null;
      if (this.selectedEdge == null) {
        this.selectedEdge = value;
        this.$emit("input", [value, value]);
      } else {
        this.emitChange(
          this.selectedEdge.isBefore(value)
            ? [this.selectedEdge, value]
            : [value, this.selectedEdge]
        );
      }
    },
    emitChange(newWrappedValue) {
      if (newWrappedValue.length === 2 || newWrappedValue.length === 0) {
        this.selectedEdge = null;
      }

      if (newWrappedValue.length) {
        this.$emit("input", [
          newWrappedValue[0].clone().tz(this.timezone).startOf("day").format(),
          newWrappedValue[1].clone().tz(this.timezone).endOf("day").format(),
        ]);
      } else {
        this.$emit("input", []);
      }

      // this.opened = false
    },
    accept() {
      this.opened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-dropdown {
  position: absolute;
  background-color: #fff;
  top: calc(100% + 12px);
  padding: 24px 0;
  box-shadow: 0 35px 80px rgba(133, 133, 175, 0.6);
  border-radius: 4px;
  z-index: 10;

  @media screen and (max-width: 1279px) {
    left: 0;
    transform: translate(0, 0);
    height: 40vh;
    width: 100%;
    overflow-y: auto;
  }

  @media screen and (min-width: 1280px) {
    left: 0;
  }

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 12px 8px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    bottom: 100%;

    @media screen and (max-width: 1279px) {
      left: 20px;
    }

    @media screen and (min-width: 1280px) {
      left: 140px;
      transform: translateX(-50%);
    }
  }
}

.calendar {
  min-width: 280px;

  .icon-calendar {
    margin-left: 10px;
  }

  &.opened {
    .calendar-trigger__inner {
      border-color: #5378fb;
    }

    .icon-calendar {
      color: #5378fb;
    }
  }

  &-wrapper {
    @media screen and (max-width: 1279px) {
      gap: 20px;
      padding-bottom: 20px;
    }

    @media screen and (min-width: 1280px) {
      display: grid;
      padding-bottom: 45px;
      grid-template-columns: 240px 1fr;
    }

    &__inner {
      display: flex;

      @media screen and (max-width: 1279px) {
        flex-wrap: wrap;
      }
    }
  }

  &-footer {
    padding: 24px 24px 0;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    border-top: 1px solid fade-out(#00004f, 0.9);

    @media screen and (max-width: 1279px) {
      justify-content: center;
    }

    @media screen and (min-width: 1280px) {
      margin-left: auto;
      width: calc(100% - 240px);
    }

    .btn {
      @media screen and (max-width: 1279px) {
        flex: 0 1 50%;
      }

      @media screen and (min-width: 1280px) {
        flex-basis: 160px;
      }
    }
  }
}

.calendar-trigger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: border-color 0.2s ease;

  > span {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 16px;
  }

  &__inner {
    border: 1px solid rgba(83, 120, 251, 0.25);
    border-radius: 4px;
    background: #fff;
    font-weight: 500;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 19px;
    }

    @media screen and (min-width: 768px) {
      font-size: 18px;
      line-height: 32px;
    }
  }
}

.calendar-list {
  @media screen and (max-width: 1279px) {
    flex: 0 0 100%;
  }

  @media screen and (min-width: 1280px) {
    flex: 0 0 240px;
  }

  &-item {
    &::before {
      content: "";
      position: absolute;
      width: 2px;
      height: 100%;
      left: 0;
      top: 0;
      background: transparent;
      z-index: 2;
    }

    &.custom {
      pointer-events: none;
      cursor: not-allowed;
      user-select: none;
    }

    label {
      color: fade-out(#00004f, 0.6);
      padding: 6px 24px;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: background-color 0.2s ease;

      @media screen and (max-width: 1279px) {
        font-size: 14px;
        line-height: 19px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 18px;
        line-height: 32px;
      }

      input {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
      }

      &:hover {
        background-color: #eaeeff;
      }
    }

    &.active {
      label {
        background-color: #eaeeff;
        font-weight: 500;
        color: #00004f;
      }

      &::before {
        background: #3a58ff;
      }
    }
  }
}
</style>
