<template>
  <div class="subset-input" v-click-outside="() => (isOpened = false)">
    <p class="subset-input__label">{{ label }}</p>
    <div
      :class="['filter js-control-filter', { _opened: isOpened }]"
      :data-type="label"
    >
      <div
        :class="['filter__header', { _active: isOpened }]"
        @click="isOpened = true"
      >
        <div class="filter__item">
          <p
            :class="['filter__header--title', { 'js-selected': this.value }]"
            ref="title"
          >
            {{ title }}
          </p>
        </div>
        <i class="icon-angle-down icon"></i>
      </div>
      <div class="filter__body dropdown-body">
        <input
          class="dropdown-search"
          type="text"
          placeholder="Поиск..."
          @input="$emit('search', $event.target.value)"
          v-if="with_search"
        />
        <div class="dropdown-wrapper">
          <label
            class="filter__item dropdown-item"
            ref="all_categories"
            v-if="!onlyOnce"
          >
            <span>Выбрать все</span>
            <input
              type="checkbox"
              name="all"
              @click="selectAll"
              ref="selectAllCheckbox"
            />
            <div class="check"><i class="icon-ok icon"></i></div>
          </label>
          <label
            v-for="o of effectiveOptions"
            :key="o.id"
            class="filter__item dropdown-item"
          >
            <span>{{ o.name }}</span>
            <input
              type="checkbox"
              :name="label"
              :value="o.id"
              :checked="value.includes(o.id)"
              @click="toggle(o.id)"
            />
            <div class="check"><i class="icon-ok icon"></i></div>
          </label>
        </div>
        <div class="filter__actions">
          <Button
            class="_filled btn-primary"
            type="button"
            size="sm"
            @click.prevent="isOpened = false"
            text="Выбрать"
          />
          <Button
            className="_outlined"
            type="button"
            size="sm"
            @click.prevent="clear"
            text="Очистить"
            v-if="!onlyOnce"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
export default {
  props: {
    label: String,
    value: Array,
    options: Array,
    with_search: {
      type: Boolean,
      default: true,
    },
    onlyOnce: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { isOpened: false, search: "" };
  },
  components: {
    Button,
  },
  mounted() {
    const { title } = this.$refs;
    title.style.width = title.parentElement.clientWidth - 20 + "px";
  },
  computed: {
    effectiveOptions() {
      return this.options
        .slice()
        .sort((a, b) => this.value.includes(b.id) - this.value.includes(a.id));
    },
    title() {
      return this.value.length
        ? this.value
            .map((id) => {
              const o = this.options.find((o) => o.id === id);
              return o ? o.name : null;
            })
            .filter(Boolean)
            .join(", ")
        : this.label;
    },
  },
  methods: {
    selectAll() {
      this.$emit(
        "update:value",
        this.options.map((o) => o.id)
      );
    },
    toggle(id) {
      if (!this.onlyOnce) {
        this.$refs.selectAllCheckbox.checked = false;
        this.$emit(
          "update:value",
          this.value.includes(id)
            ? this.value.filter((x) => x !== id)
            : [...this.value, id]
        );
      } else {
        this.$emit("update:value", [id]);
      }
    },
    clear() {
      this.$refs.selectAllCheckbox.checked = false;
      this.$emit("update:value", []);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";
.subset-input {
  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    max-width: 280px;
    width: 280px;
  }
}

.dropdown-search {
  width: calc(100% - 32px);
  margin: 8px auto;
  display: block;
  padding: 4px 8px;
  border-bottom: 1px solid rgba(128, 128, 167, 0.25);
}

.subset-input__label {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 16px;
}
.filter {
  border-radius: 4px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 19px;
  }
  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 32px;
  }
  &__actions {
    display: flex;
    gap: 8px;
    padding: 16px;
  }
  .btn {
    flex: 1 1 50%;
  }

  .dropdown-search {
    display: block;
    width: 100%;
    padding: 8px 16px;
    border-bottom: 1px solid rgba(124, 131, 253, 0.25);
    margin: 8px auto;
  }

  &__header {
    background: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid rgba(83, 120, 251, 0.25);
    color: fade-out($color-base, 0.6);
    &--title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
  }
  &__body {
    width: 100%;
    position: absolute;
    top: calc(100% + 5px);
    border-radius: 4px;
    box-shadow: 0 35px 80px rgba(133, 133, 175, 0.6);
    background: #fff;
    z-index: 5;
    display: none;
    .filter__item {
      &:hover {
        background: #eaeeff;
      }
    }
  }
  .dropdown-wrapper {
    max-height: 200px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      background: #eaeeff;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #cdd1f3;
    }
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
  }
  &__item {
    display: flex;
    cursor: pointer;
    overflow: hidden;
    padding: 4px 16px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
      display: block;
      left: 0;
      top: 0;
    }
    > span {
      order: 1;
    }
    .check {
      width: 18px;
      height: 18px;
      border: 1px solid rgba(124, 131, 253, 0.25);
      border-radius: 2px;
      margin-right: 14px;
      order: 0;
      background: #dfdfff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 7px;
      .icon {
        font-size: 12px;
        color: #fff;
        opacity: 0;
      }
    }
    input:checked + .check {
      background: #7c83fd;
    }
    input:checked + .check .icon {
      opacity: 1;
    }
  }
  &__header {
    .filter__item {
      padding: 0;
    }
  }
  &._opened {
    .filter__header {
      color: $color-base;
      .icon {
        &::before {
          transform: rotate(180deg);
        }
      }
    }
    .filter__body {
      display: block;
    }
  }
}
</style>
